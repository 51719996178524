@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  width: 100%;
  .valuesBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include screen(max-sm) {
      div {
        .minimumBidTitle {
          font-size: $font-size-16;
        }
        .minimumBidText {
          font-size: $font-size-14;
        }
      }
    }

    .economyBox {
      position: relative;
      border-radius: 2px;
      border: solid 1px $gray3;
      padding: 12px 28px 17px 25px;

      &::after {
        content: ' ';
        width: 8px;
        height: 8px;
        position: absolute;
        left: 0;
        top: 50%;
        background-color: $secondary-color;
        transform: translateY(-50%) translateX(70%) rotate(45deg);
      }

      .percentualDiscount {
        position: absolute;
        border-radius: 1px;
        background-color: $secondary-color;
        left: 0;
        top: 50%;
        transform: translateY(-50%) translateX(-85%);
        padding: 5px 10px;
        display: flex;
        align-items: center;

        .downArrow {
          display: flex;
          align-items: center;
        }
      }
      .valueDiscount {
        line-height: 1.08;
      }

      @include screen(max-sm) {
        &::after {
          transform: translateY(-50%) translateX(40%) rotate(45deg);
        }

        .valueDiscount {
          font-size: $font-size-12;
        }
        .economyText {
          font-size: $font-size-14;
        }
        .percentualDiscount {
          padding: 3px 5px;
          .percentualDiscountText {
            font-size: $font-size-12;
          }
        }
      }
    }
  }
  .line {
    width: 100%;
    height: 6px;
    border-radius: 4px;
    background-color: $gray7;
    margin: 20px 0px;
    position: relative;

    .lineDiscount {
      height: 14px;
      border-radius: 7px;
      box-shadow: 0 -2px 6px -1px rgba(0, 0, 0, 0.3);
      background-color: $secondary-color;
      position: absolute;
      margin-top: -4px;
      right: 0;
      max-width: 100%;
    }
  }
}
