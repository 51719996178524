@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.preActiveCountDown {
  div[class*='countDownContainer'] {
    flex-direction: row-reverse;

    @include screen(max-sm) {
      visibility: visible;
    }

    [class*='subTitle'] {
      color: #101010;
      margin-top: -1px;
      font-size: 14px;
      line-height: 21px;
      margin-right: 4px;
    }

    div[class*='counterContainer'] {
      div[class*='box'] {
        > span {
          &:first-child {
            display: none;
          }
        }
        span[class*='numberBox'] {
          background-color: transparent;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: $secondary-color;
          padding: 0.5px 1.5px;
        }
      }
      span[class*='colon'] {
        margin-top: -1.5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $secondary-color;
      }
    }
  }
}
