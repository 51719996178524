//Basic colors
$white-color: #ffffff;
$black-color: #000000;

//Primary, second and tertiary colors
$primary-color: #006754;
$secondary-color: #00af93;
$tertiary-color: #fb4e5f;

//Primary, secondary and tertiary variations colors
$primary-color-light: #128771;

//Blue and Lavender colors
$blue0: #a0b6ff;
$blue1: #7896ff;
$blue2: #215297;
$blue3: #23355a;
$lavender0: #828296;

//Gray colors
$gray0: #f5f5f5;
$gray1: #efefef;
$gray2: #e7ebf4;
$gray3: #dedede;
$gray4: #dadadf;
$gray5: #c4c4c4;
$gray6-translucid: #f8f8f8a6;
$gray7: #a8b5b8;
$gray8: #919aad;
$gray9: rgb(70, 70, 70);
$gray10: #222;
$gray11: rgba(255, 255, 255, 0.4);
$gray12: #f3f3f4;
$gray13: #dadade;
$gray14: rgb(249, 249, 249);
$gray15: #6f7779;
$gray16: #262626;

//Others colors
$yellow-color: #fecf33;
$orange-color: #f38701;
$gunmetal-color: #4d5354;

//Text colors
$text-color: #454545;
$text-black-color: #101010;

// Font sizes
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-35: 35px;
$font-size-36: 36px;
$font-size-40: 40px;
$font-size-60: 60px;

//Font families
$font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$font-family-open-sans: 'Open Sans';

//Others
$notification-box-rosie-size: 85px;
