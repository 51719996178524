@import 'assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

* {
  font-family: $font-family;
}

html {
  font-size: 100%;
  font-family: $font-family;
  color: $blue3;
}

.background-white {
  background-color: $white-color;
  padding-bottom: 70px;
}

.background-gray {
  background-color: $gray14;
  padding-bottom: 70px;
}

.fixedTop {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}
