@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.wrappedModal {
  z-index: 4;
  min-height: 10vh;
  max-height: 90vh;
  width: 100vw;
  box-sizing: border-box;
  max-width: 650px;
  padding: 1.2em 1.2em 1.2em;
  overflow: auto;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.05);
  background-color: $white-color;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  zoom: 1.02;
  transform: translate3d(-50%, -50%, 0);
  position: fixed;
  top: 50%;
  left: 50%;

  &.noOverflowX {
    overflow-x: hidden;
  }

  > .clear {
    position: absolute;
    z-index: 1;
    top: 10px;

    &.right {
      right: 20px;
    }

    &.left {
      left: 24px;
    }

    .closeButton {
      text-decoration: none;
      border: 0;
      background: none;
      padding: 0;
      cursor: pointer;
      height: 32px;
      width: 32px;
      margin: -4px 0 0 -16px;

      &:focus {
        outline: none;
      }
    }
  }

  @include iOS() {
    top: 55%;
  }
  @include screen(xs) {
    height: 100vh;
    max-height: 100vh;
    padding: 1.2em 1.2em;
    .clear {
      top: 8%;
    }
  }
}

.wrappedModal.active {
  visibility: visible;
}

.backdrop {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
