@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.imageContainer {
  display: flex;
  flex-direction: row;

  img {
    image-rendering: -webkit-optimize-contrast;
  }

  @include screen(max-lg) {
    display: grid;
  }
}

.wrapper {
  margin: 0 auto 70px;
  @include screen(xxl) {
    width: 1500px;
  }
}

.property {
  display: flex;
  padding: 0 30px;
  justify-content: center;

  @include screen(max-md) {
    padding: 0;
  }
}

.data {
  flex: 2 1 0;
  max-width: 780px;
  width: 100%;
}

.card {
  flex: 1 1 0;
  max-width: 450px;
  width: 100%;

  @include screen(max-md) {
    display: none;
  }
}

.bannerImageLarge {
  width: 100%;
  display: none;

  @include screen(xxl) {
    display: block;
  }
}

.bannerImage {
  width: 100%;

  @include screen(xs) {
    display: none;
  }

  @include screen(xxl) {
    display: none;
  }
}

.bannerImageMobile {
  width: 100%;

  @include screen(min-sm) {
    display: none;
  }
}