@import '../../assets/scss/variables.scss';

.title {
  font-weight: 600;
  margin: 3px 0;
}

.bold {
  font-weight: bold;
}

.click {
  cursor: pointer;
}

.primaryColor {
  color: $primary-color;
}

.secondColor {
  color: $secondary-color;
}

.blackColor {
  color: $text-black-color;
}

.whiteColor {
  color: $white-color;
}

.font-xxsmall {
  font-size: $font-size-10;
}
.font-xsmall {
  font-size: $font-size-12;
}
.font-small {
  font-size: $font-size-16;
}
.font-medium {
  font-size: $font-size-18;
}
.font-large {
  font-size: $font-size-20;
}
.font-xlarge {
  font-size: $font-size-24;
}
.font-xxlarge {
  font-size: $font-size-30;
}
.font-xxxlarge {
  font-size: $font-size-40;
}
.font-xxxxlarge {
  font-size: $font-size-60;
}
.textCenter {
  text-align: center;
}
