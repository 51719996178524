@import '../../assets/scss/variables.scss';

.select {
  display: flex;
  flex-direction: column;

  > label {
    > span {
      > select {
        border-radius: 4px;
        background-color: $white-color;
        padding: 0px 11px;
        box-sizing: border-box;
        outline: 0;
        height: 38px;
        width: 100%;
        font-weight: normal;
        margin-top: 5px;
        font-size: $font-size-14;
        color: $text-black-color;
        border: 1px solid $gray5;
        display: flex;
        align-items: center;

        &:focus {
          outline: 0;
        }
      }
    }
  }
}

.select.error {
  > label {
    > span {
      select {
        border-color: $tertiary-color;
      }

      .label {
        color: $tertiary-color;
      }
    }
  }
  .span {
    align-self: flex-end;
    color: $tertiary-color;
    font-size: $font-size-14;
  }
}
