@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.root {
  display: flex;

  .button {
    border: 0;
    border-radius: 0;
    background: none;
    color: transparent;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 25px;
    margin-right: 8px;
  }
  .dotSelected {
    background-color: $primary-color;

    // todo: remover quando acabar a campanha
    background-color: transparent;
  }

  .dotUnselected {
    background-color: $gray5;
  }
}
