@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

[class*='multiple-carousel'] {
  z-index: 2;
}

.modalCamp {
  width: 410px;
  padding: unset;
  border: unset;
  background-color: transparent;
  overflow: hidden;

  @include screen(xs) {
    width: 97vw;
  }

  [class*='countDownContainer'] {
    @include screen(max-sm) {
      visibility: unset;
    }
  }

  [class*='image'] {
    border-radius: 16px;
    object-fit: fill;
    image-rendering: -webkit-optimize-contrast;
  }

  [class*='clear'] {
    top: 24px;
    left: unset !important;
    right: 18px;
    use {
      fill: $white-color !important;
    }
  }

  div {
    position: relative;
    .countDown {
      position: absolute;
      left: 21%;
      bottom: 19%;
      zoom: 1.2;

      @include screen(max-sm) {
        left: 20%;
      }

      @media (min-width: 320px) and (max-width: 324px) {
        left: 16%;
      }
    }
  }

  button[class*='react-multiple-carousel__arrow'] {
    box-shadow: unset;
    color: #fff9f9 !important;
    margin: 0 auto;
    transition: all 0.3s ease 0s;
    top: 50%;
    background-color: #f9f9f9 !important;
    min-width: unset;
    min-height: unset;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: 1;

    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black-color;
      font-size: 12px;
    }
    &:hover {
      color: #111111 !important;
    }
  }

  div[class*='magic-dots'] {
    position: absolute !important;
    bottom: 2% !important;
    margin: 0 auto;

    > ul {
      > li {
        button {
          &::before {
            opacity: unset;
            color: $white-color;
          }
        }
      }

      > li[class*='active'] {
        button {
          &::before {
            color: $primary-color;
          }
        }
      }
    }
  }

  div[class*='slick-slider'] {
    display: flex;
    justify-content: center;
  }

  div[class*='slick-list'] {
    width: 100%;
    height: 100%;
  }

  @include screen(xs) {
    margin-top: 24px;
  }
}
