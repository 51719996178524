.containerImg {
  width: 100%;
  margin-bottom: 10px;
  
      img {
        width: 100%;
        height: 84px;
      }

  > a {
    text-decoration: none;
  }
}