@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$alert-card-padding: 22px;

.alertCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color;
  border: 1px solid $gray3;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin-bottom: 14px;

  .info {
    margin-left: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > p {
      margin-bottom: 3px;
    }
  }

  .text {
    margin-bottom: 5px;
  }
  .emailInfo {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;

    .emailInput {
      width: 99%;
      height: 37px;

      label {
        margin: 0;
      }

      input {
        border-radius: 2px;
      }
    }
  }

  .container {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .body {
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .figure {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    border-radius: 100px;

    .img {
      width: 140px;
      height: 140px;
    }
  }

  .buttonSubmit {
    width: 120px;
  }

  @include screen(max-md) {
    width: auto;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $primary-color;
    position: relative;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px;

    .body {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      align-items: flex-start;
    }

    .info {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 35px;

      width: 100%;
      text-align: center;
    }

    .text {
      margin-top: 5px;
    }

    .emailInfo {
      display: flex;
      flex-direction: column;
    }

    .buttonSubmit {
      margin-top: 16px;
      width: 60%;
      align-self: center;
    }

    .container {
      display: flex;
      flex-direction: column;
    }

    .figure {
      position: absolute;
      top: -40px;
      left: 50%;
      margin-left: -40px;
      border: 4px solid $white-color;
      background-color: white;
      width: 76px;
      height: 76px;

      .img {
        width: 76px;
        height: 76px;
      }
    }
  }
}
