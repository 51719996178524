@import '../../assets/scss/mixins.scss';

.tagSetContainer {
  width: 100%;
  margin: 2% 0;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media(min-width: 950px) {
    display: none;
  }

  &.carrouselMode {
    flex-wrap: wrap;
    overflow-x: none;
    justify-content: flex-start;
    @media(min-width: 950px) {
      display: none;
    }
  }
}
