@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.AsteriskExplain {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 90%;
  padding: 1em;
  margin: 1em 0;
  text-align: right;
  background-color: $gray0;
}
