@import '../../assets/scss/variables.scss';

.image {
  width: 100%;
  height: 100%;
}

.button {
  all: unset;
  background: none;
  border: none;
  width: 100%;
  padding: 0;
  cursor: pointer;
}
