@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.tag {
  display: inline-flex;
  text-align: center;
  white-space: nowrap;
  position: relative;
  padding: 0px 7px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 50px;
  background-color: transparent;
  color: $primary-color;
  font-size: $font-size-12;
  border: 1px solid $primary-color;

  &.greenTag {
    background-color: $primary-color;
    color: $white-color;
    font-weight: 600;
    border: none;
  }

  &.feirao {
    background-color: $primary-color;
    color: $white-color;
    font-weight: 600;
    max-height: 20px;
    border: 1px solid $primary-color;
  }
  &.blackout {
    background-color: #000000;
    font-weight: 600;
    color: $white-color;
    max-height: 20px;
    border: 1px solid #000000;
  }

  &.blackoutDay {
    background-color: #000000;
    border: 1px solid #000000;
    max-height: 20px;
  }

  &.preActive {
    background-color: #fffffe;
    border-color: transparent;
    color: #262626;
    height: 24px;
    letter-spacing: 0.260571px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    align-items: center;

    svg {
      padding-top: 8%;

      path {
        fill: $secondary-color;
      }
    }
  }
}

.primaryTag {
  text-align: center;
  white-space: nowrap;
  padding: 0px 7px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 50px;
  font-size: $font-size-12;
  border: 1px solid white;
  background-color: $primary-color;
  color: white;
}

.deletable {
  @extend .tag;
  margin-top: 6px;
  background-color: $gray1;
  border: none;
  color: $blue3;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .closeButton {
    display: block;
    margin-left: -5px;
    border: none;
    position: relative;
    cursor: pointer;
    background: none;

    &:active {
      outline: none;
    }

    svg {
      margin-bottom: -25%;
    }
  }
  @include screen(xs) {
    padding: 1.5%;
    padding-left: 5%;
  }

  @include screen(sm) {
    padding: 1%;
    padding-left: 3%;
  }
}

b {
  padding-left: 2.5px;
}

.icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  margin-right: 5px;
  align-self: center;

  &.blackoutIcon {
    width: 48px;
    height: 48px;
    margin-right: 0;
  }

  &.blackoutDayIcon {
    width: 42px;
    height: 18px;
    margin-right: 0;

    img {
      object-fit: cover !important;
    }
  }
}

.campaignTag {
  font-weight: 600;
  border: unset;
  max-height: 20px;
}

.megaFeiraoEmgea {
  path {
    fill: #fa9003;
  }
}

.feirao15 {
  path {
    fill: #006854;
  }
}
