@import "../../assets/scss/mixins.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include screen(xs) {
    justify-content: center;
    min-width: 0;
    width: 100%;
    min-height: 100px;
  }

  .container{
    width: 100%;
  }
}