@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.faqContainer {
  max-width: 980px;
  margin: 64px auto 120px;
}

:global(body) {
  background: $gray12;
}

.mainTitle {
  font-size: $font-size-36;
  margin-bottom: 50px;
  margin-left: -50px;
  @media (max-width: 1125px) {
    text-align: center;
    margin-left: 0;
  }
}
