@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$small-screen: 768px;

.modal {
  position: absolute;
  top: 50;
  bottom: 50;
}

.card {
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.05);
  background-color: $white-color;

  border: 1px solid $gray1;
  border-radius: 4px;

  padding: 1.5em;
  margin: 30px;
  margin-top: 0px;

  @include screen(xxl) {
    margin-top: 75px;
  }
}

.facaSuaPropostaButton {
  margin-top: 15px;
  width: 100%;
  font-size: 16px;

  @include screen(max-md) {
    width: 98%;
  }
}

.largeButton {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.halfButton {
  width: 48%;
}

.link {
  text-decoration: none;
}

@media (max-height: 768px) {
  .card {
    margin-top: 0px;
    margin-bottom: 15px;
  }
}

@include screen(max-md) {
  .singleButtonContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    align-self: center;
    background-color: $white-color;
    text-align: center;

    > button {
      width: 90%;
    }
  }

  .buttonContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    align-self: center;
    background-color: $white-color;
    display: flex;
    padding: 16px 0;
    left: 0;
    z-index: 1;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
  }

  .card {
    display: flex;
    flex-direction: column;
    margin: 0px 0;

    box-shadow: none;
    border: none;
    border-radius: none;
    background-color: $gray0;
    position: relative;
    padding: 1em 1.2em;
  }

  .largeButton {
    border-radius: 20px;
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.25);
    margin: 0;
    width: 95%;
  }

  .halfButton {
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 45%;
    margin-top: 10px;
  }

  .link {
    width: 80%;

    > .largeButton {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.subTitleCampaign {
  color: #888888;
  font-size: 0.6em;
  font-weight: 500;
}
