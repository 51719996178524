@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.list {
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  max-width: 100vw;
  margin-bottom: 96px;

  > button {
    margin: 0 -2.5% !important;
    background-color: white !important;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 15px 20px rgba(197, 197, 197, 0.4);
    color: #333 !important;
    margin: 0 auto;
    transition: all 0.3s ease 0s;
    top: 50%;

    &::before {
      color: #888;
    }
    &:hover {
      background-color: #f4f4f4 !important;
    }
  }

  article {
    margin-top: 32px;
    height: 440px;
    > p {
      font-size: 18px;
      font-weight: 400;
    }
  }

  @include screen(xs) {
    article {
      width: 315px;
      height: 450px;
    }
  }

  button[class*='arrow'] {
    z-index: 1;
  }
}
