@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.cookiesMobile {
  @include screen(min-md) {
    display: none;
  }
  @media (min-width: 640px), (max-width: 900px) and (orientation: landscape) {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;

  h1 {
    text-align: center;
  }

  p {
    margin-top: 10px;
  }

  a {
    color: $primary-color;
    font-weight: bold;
    margin-left: 4px;
  }

  .button {
    width: 100%;
    margin-top: 24px;
    font-size: $font-size-12;
  }

  .ghostButton {
    font-weight: normal;
    color: $gray15;
    margin-top: 5px;
  }
}

.cookiesModal {
  width: 280px !important;
  height: auto !important;
  padding-top: 2em !important;
  z-index: 6 !important;
}

.cookiesBackdrop {
  z-index: 5 !important;
}
