@import '../../assets/scss/variables.scss';

$input-height-increment: 8px;
.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  > .input:focus {
    outline: 0;
  }

  > .input {
    background: $white-color;
    border-radius: 3px;
    border: solid 1px $gray4;
    box-sizing: border-box;
    color: $gray15;
    font-size: $font-size-14;
    height: calc(28px + #{$input-height-increment});
    padding: 6px 15px 4px 31px;
    position: relative;
    width: 124px;
  }

  > .label {
    color: $gray15;
    font-size: $font-size-14;
    left: 13px;
    position: absolute;
    top: calc(3px + #{$input-height-increment * 0.7});
    z-index: 1;
  }
}
