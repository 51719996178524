@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.filtersContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 8.5%;
}

.filterSection {
  padding: 5px;
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;

  > section {
    margin: 1em 0px;

    > h3 {
      margin-bottom: 16px;
    }
  }

  @include screen(xs) {
    width: unset;
    max-width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 5px 15px;

  > p {
    align-self: center;
  }
}

.linkLike {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  border: 0;
  color: $primary-color;
  background: none;
  font-size: $font-size-16;
  height: 20px;

  > span {
    margin-left: 4px;
  }

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }
}

.clearLink {
  @extend .linkLike;

  position: absolute;
  right: 22px;
  margin-top: 5px;
}

.filterModalClear {
  top: 3% !important;
}

button.showAdvancedFiltersButton {
  @extend .linkLike;
  margin-bottom: 60px;
}

.arrowClose {
  transform: rotate(270deg);

  @include iOS {
    transform: rotate(0deg);
  }
}

.arrowOpen {
  transform: rotate(90deg);

  @include iOS {
    transform: rotate(180deg);
  }
}

.confirmButtonContainer {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  bottom: -1.2em;
  z-index: 1;
  background-color: $white-color;
  padding: 15px 0;
  display: block;
  justify-content: center;

  @media (min-width: 480px) {
    bottom: -.5rem;
  }
}

.confirmButton {
  align-self: center;
  width: 100%;
  height: 40px;
  background-color: $primary-color;
  border-radius: 4px;
  box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.25);

  @include iOS {
    margin-bottom: 50px;
  }
}

.modal {
  padding-bottom: 0;
  box-sizing: border-box;
  @include screen(xs) {
    height: 98vh;
    max-height: 900vh;
    padding: 1.2em 1.2em;
  }
}
