@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$small-screen: 768px;

.card {
  display: inline-flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  margin-bottom: 14px;

  min-width: 290px;
  margin-right: 20px;

  a {
    text-decoration: none;
    position: relative;
  }

  > .image {
    overflow: hidden;
    margin: 0;
    cursor: pointer;
    position: relative;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    height: 214px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  > .information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 10px;
    border-bottom: 0.1px solid $gray13;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: auto;
    height: 128px;

    .state {
      text-align: left;
      color: $primary-color;
      font-weight: bold;
      margin-left: 4px;
    }

    .propertyTitle {
      font-size: $font-size-18;
      font-weight: 600;
      color: $blue3;
    }

    > .tags {
      margin-bottom: 2px;
      max-width: 300px;
      flex-wrap: wrap;
      display: flex;
    }

    > .features {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 32px;

      margin-top: 5px;
    }
  }

  > .values {
    display: flex;
    padding: 15px;
    border-left: 1px solid $gray1;
    width: 90%;
    align-self: center;
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 0px;
    border: none;
    min-height: 86px;

    p {
      font-size: $font-size-16;
    }

    > .saleOnly {
      margin-bottom: 10px;
    }

    > .prices {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      align-items: center;
      margin-bottom: 10px;

      div {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        p {
          font-size: $font-size-16;
        }
      }

      > .discount {
        display: flex;
        flex-direction: column;
        width: 100%;

        .percent {
          font-size: $font-size-18;
        }
      }
    }
  }
}

.ellipsis {
  font-size: $font-size-14;
  color: $text-black-color;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: initial;

  @include screen(max-md) {
    margin-top: 0.5em;
  }
}

.link {
  text-decoration: none;
  color: $primary-color;
  font-weight: bold;
  font-size: $font-size-18;
  text-align: left;
}

.sold {
  & span {
    background-color: $secondary-color;
    color: $white-color;
    width: 100%;
    font-size: $font-size-14;
    font-weight: bold;
    letter-spacing: 0.9px;
  }

  & div {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    display: flex;
    top: 10%;
    left: -30%;
    padding: 5px 0;
    text-align: center;
    transform: rotate(-45deg);
    height: 25px;

    @include screen(xs) {
      top: 8%;
      left: -25%;
      width: 80%;
    }

    @include screen(max-sm) {
      width: 80%;
    }

    @include screen(min-md) {
      width: 80%;
    }

    @include screen(max-md) {
      width: 75%;
    }

    @include screen(min-lg) {
      width: 100%;
    }
  }
}
