@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.aboutBanner,
.pageBanner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 240px;
  padding: 0 5%;

  > h1 {
    font-size: 2.5em;
    font-weight: bold;
  }

  @include screen(max-md) {
    height: 160px;

    > h1 {
      font-size: 2em;
    }
  }

  @include screen(xs) {
    height: 100px;

    > h1 {
      font-size: 1em;
    }
  }

  @include screen(max-sm) {
    height: 100px;

    > h1 {
      font-size: 1em;
    }
  }
}

.aboutBanner {
  position: relative;
  background-color: $gray2;

  > h1 {
    color: $blue3;
  }
}

.pageBanner {
  background-color: $primary-color;
  background-image: url('../../assets/images/go-resale.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 90%;

  > h1 {
    color: white;
    font-weight: bold;
  }
}

.image {
  position: absolute;
  top: 0;
  right: 5%;
  transform: translateY(7%);
  height: 133%;
  max-height: 320px;
}
