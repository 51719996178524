@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.messageModal {
  display: flex;
  flex-direction: column;
  margin: 48px 14px;

  @include screen(xs) {
    padding-bottom: 40%;
  }

  > .header {
    display: flex;
    position: relative;
    width: 100%;

    > .info {
      display: flex;
      flex-direction: column;
      text-align: center;

      .contactText {
        margin-top: -2px;
        padding: 0 69px;

        @include screen(xs) {
          padding: 0;
        }
      }

      .collapse {
        color: $primary-color;

        .upArrow {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
          margin-top: 6px;
        }

        .downArrow {
          -webkit-transform: rotate(270deg);
          -moz-transform: rotate(270deg);
          -o-transform: rotate(270deg);
          -ms-transform: rotate(270deg);
          transform: rotate(270deg);
        }
      }
    }

    > .logo {
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  > .main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 16px;

    > .inputName {
      width: 100%;
      margin-top: 17px;
    }

    > .checkbox {
      justify-content: flex-end;
      margin-top: 11px;
      padding: 0;
      margin-bottom: 0px;

      input {
        height: auto;
      }
    }

    > .inputPhone,
    > .inputConfirmPassword {
      width: 50%;
      padding-left: 3%;
      box-sizing: border-box;
    }

    > .inputEmail {
      width: 50%;
      padding-right: 2%;
      box-sizing: border-box;
    }

    > .inputPassword {
      margin-top: 5px;
      width: 100%;
      padding-right: 2%;
      box-sizing: border-box;
    }

    .passwordContainer {
      width: 50%;
    }

    .modalLabel {
      margin-bottom: 3px;
    }

    input {
      height: 40px;
    }

    > .inputName,
    > .inputPhone,
    > .inputConfirmPassword {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 0;
        font-size: $font-size-12;
      }
    }

    > .inputEmail,
    > .inputPassword {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 11px;
        font-size: $font-size-12;
      }
    }

    .recoverPasswordButton {
      color: $blue3;
      font-weight: normal;
      height: auto;
      margin-top: 5px;
    }

    > .beforeFooter {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 17px;

      strong {
        font-weight: bold;
      }
    }
  }

  > .footer {
    justify-content: center;
    display: flex;

    .submit {
      min-width: 30%;
    }

    .link {
      text-decoration: none;
      color: $primary-color;
      font-size: $font-size-14;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.strong {
  strong {
    font-weight: bold;
  }
}

.pagImovel {
  padding: 21px 24px;
  border-radius: 2px;
  background-color: $gray12;
  margin-bottom: 15px;

  .pagimovelLogo {
    height: 28px;
    width: 120px;
    margin-left: 7px;
    margin-right: 40px;
  }
}

.optins {
  width: 100%;
  margin-top: 25px;
}

.reCAPTCHA {
  width: 50%;
  margin: 3% 25%;
  @media (max-width: 490px) and (min-width: 290px) {
    width: 100%;
    margin: 0 auto;
  }
}
