@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variables.scss';

.campaignCountDown {
  [class*='countDownContainer'] {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin: 0px 30px 2% 30px;

    @include screen(max-sm) {
      visibility: visible;
    }
  }
  [class*='subTitle'] {
    color: $gray16 !important;
    margin-top: 40px !important;
  }
  div[class*='box'] {
    span[class*='title'] {
      color: $gray16 !important;
    }
    span[class*='numberBox'] {
      background-color: $gray16 !important;
      color: $white-color !important;
    }
  }
}
