@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.wrapper {
  min-height: 450px;
  top: 0;
  bottom: 200px;
  margin-top: 10px;
  transition: 1s;

  overflow-y: visible;

  -ms-overflow-style: none;

  .wrapper::-webkit-scrollbar {
    display: none;
  }

  @include screen(max-md) {
    .wrapper {
      margin-top: 10px;
      margin-bottom: 0px;
      min-height: 0;
      position: relative;
      height: auto;

      .offerCardDiv {
        padding: 10px 0;
        .offerInCard {
          margin: 0;
          width: auto;
        }
      }
    }
  }
}
