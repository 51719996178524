@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$headerHeight: 10vh;
$pagePaddingTop: 10vh;

.maintenancePage {
  @include flexBox(column);
  width: 100vw;
  height: calc(100vh - #{$pagePaddingTop});
  position: relative;
  padding-top: $pagePaddingTop;

  .maintenanceHeader {
    @include flexBox();
    position: absolute;
    height: 10vh;
    top: calc(#{$pagePaddingTop} / 2);

    .logo {
      width: 150px;
    }
  }

  main {
    @include flexBox();

    .logoIcon {
      width: 300px;
    }

    .disclaimerContainer {
      width: 50%;
      margin-left: 5%;

      .disclaimerHeader {
        margin-bottom: 3%;

        h1 {
          font-size: $font-size-32;
          color: $blue1;
        }

        h2 {
          font-size: $font-size-24;
          color: $blue0;
        }
      }
      .disclamerTextContainer {
        width: 70%;

        p {
          font-size: $font-size-24;
          color: $blue3;
          font-weight: bold;
        }
      }
    }
  }

  @include screen(max-sm) {
    @include flexBox(column);

    main {
      @include flexBox(column);

      .logoIcon {
        width: 150px;
      }

      .disclaimerContainer {
        @include flexBox(column);
        width: 100%;
        margin: 0;
        margin-top: 5%;
        text-align: center;

        .disclaimerHeader {
          h1 {
            font-size: $font-size-24;
          }

          h2 {
            font-size: $font-size-20;
          }
        }
        .disclamerTextContainer {
          p {
            font-size: $font-size-20;
          }
        }
      }
    }
  }
}
