@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.main {
  background-color: $primary-color;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 420px;

  @include screen(xs) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @include screen(xxl) {
    justify-content: center;
  }

  .contentLeft {
    margin-top: 60px;

    .text {
      text-align: center;
      font-size: $font-size-36;
      line-height: 1;

      strong {
        color: $white-color;
      }

      @include screen(xs) {
        font-size: $font-size-24;
      }
    }
  }

  .contentRight {
    min-height: 300px;
    margin: 54px 0px 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    @include screen(max-md) {
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 110px;
      height: 110px;
      margin-bottom: 30px;

      @include screen(max-md) {
        margin-bottom: 20px;
      }
    }

    .item {
      max-width: 312px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px 24px;
      text-align: center;

      @include screen(lg) {
        margin: 0px 14px;
      }

      @include screen(max-md) {
        margin-bottom: 24px;
      }

      p {
        line-height: 1.3;
        strong {
          color: $white-color;
        }

        @include screen(xs) {
          font-size: $font-size-14;
        }
      }
    }

    .middle {
      p {
        padding: 0px 45px;
      }
    }
  }
}
