@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';
.container {
  width: 100%;
  height: 100%;
  @include screen(xs) {
    .slide button {
      display: none;
    }
    .slide .button {
      display: block;
    }
  }
  .slide {
    width: 100% !important;
    position: relative;

    li {
      position: relative;
    }

    .button {
      border: 0;
      border-radius: 0;
      background: none;
      color: transparent;
      padding: 0;
      cursor: pointer;
      margin-bottom: 10px;

      &:focus {
        outline: none;
      }

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 25px;
        margin-right: 8px;
        background-color: rgba($color: #ffffff, $alpha: 0.6);
      }

      .dotActive {
        background-color: $primary-color !important;
      }
    }

    .countDown {
      position: absolute;
      bottom: 22%;
      left: 20%;

      @include screen(xxs) {
        bottom: 19% !important;
        left: 17% !important;
      }
      @include screen(xs) {
        left: 26%;
        bottom: 22%;
        zoom: 1.1;
      }

      @include screen(sm) {
        left: 30%;
        bottom: 23%;
        zoom: 1.3;
      }

      @media (min-width: 768px) and (max-width: 950px) {
        zoom: 1.2;
        left: 63%;
        bottom: 30%;
      }

      @media (min-width: 950px) and (max-width: 1024px) {
        bottom: 35%;
        left: 65%;
        zoom: 1.2;
      }

      @include screen(lg) {
        bottom: 35%;
        left: 65%;
        zoom: 1.3;
      }

      @include screen(xl) {
        bottom: 40%;
        left: 65%;
        zoom: 1.3;
      }

      @include screen(xxl) {
        bottom: 40%;
        left: 63%;
        zoom: 1.5;
      }
      @include screen(min-xxl) {
        bottom: 40%;
        left: 63%;
        zoom: 2;
      }
    }
  }
}
