@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.bannerIconWrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-evenly;

  .button {
    background: none;
    border: none;
    padding: 0;
    outline: none;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include screen(max-sm) {
      flex-wrap: wrap;
      justify-content: center;
    }

    @include screen(xs) {
      > span {
        zoom: 0.75;
      }
      flex-wrap: wrap;
      justify-content: center;
    }

    > p {
      margin-left: 8px;
      display: flex;
      flex-wrap: wrap;

      @include screen(max-sm) {
        margin-left: 4px;
        font-size: $font-size-14;
        min-width: 60px;
        line-height: 0.9;
        justify-content: center;
      }
      @include screen(xs) {
        max-width: 60px;
      }
    }
  }
}
