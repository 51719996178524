@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.financing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid $gray13;
  border-radius: 4px;
  height: 37px;
  padding: 0px 10px;
  min-width: 320px;
  margin-top: 10px;
  flex-wrap: wrap;

  > .indicator {
    height: 28px;
    display: inline-flex;
    align-items: center;
  }

  > .button {
    display: flex;
    align-items: center;
    margin-left: 4px;
    border: 0;
    background: none;
    padding: 0;
    cursor: pointer;
    font-size: $font-size-14;
    font-weight: 600;
    text-decoration: underline;

    &:focus {
      outline: none;
    }

    & a,
    a:link,
    a:visited,
    a:hover,
    a.active {
      color: $primary-color;
    }
  }

  @include screen(xs) {
    min-width: 0;
    height: auto;
    padding-bottom: 0.2em;
    justify-content: center;
  }
}
