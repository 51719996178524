@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$title-size: 0.6em;
$number-size: 1.3em;
$subtitle-size: 0.6em;

.countDownContainer {
  @include flexBox(column);
  font-size: $font-size-20;

  .counterContainer {
    display: flex;
    flex-direction: row;
    font-weight: lighter;
    line-height: initial;

    .box {
      color: $lavender0;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      .title {
        color: #fff;
        font-size: $title-size;
        font-weight: 500;
      }

      .numberBox {
        background-color: $gray14;
        color: #262626;
        font-size: 1.3em;
        font-weight: 400;
        padding: 2px 6px;
        margin: 2px 3px;
        border-radius: 5px;

        @include screen(xxl) {
          font-size: $number-size;
        }

        @media (min-width: 481px) {
          @media (max-width: 767px) {
            font-size: $number-size;
          }
        }

        @media (min-width: 768px) {
          @media (max-width: 1250px) {
            font-size: $number-size;
          }
        }
      }
    }

    .colon {
      color: #fff;
      font-size: $font-size-24;
      display: flex;
      align-items: center;
      margin-bottom: 8%;

      &:last-child {
        display: none;
      }
    }
  }

  .subTitle {
    color: #888888;
    margin-top: 10px;
    font-size: $subtitle-size;
  }
}
