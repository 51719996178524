@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.reactPaginate {
  margin: 0px;
  padding: 15px 0px;
  display: flex;
  align-items: center;
}

.reactPaginate li {
  display: inline-flex;
  height: 28px;
  width: 28px;
  margin: 5px;
  cursor: pointer;
  color: $primary-color;
  border-radius: 50px;
  justify-content: center;
  align-items: center;

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 27px;
    width: 27px;
    &:focus {
      outline: none;
    }
  }
}

.active {
  background-color: $primary-color;
  color: $white-color !important;
  border: solid 1px $primary-color;
  padding: 0;
  font-weight: 600;
}

@include screen(max-sm) {
  .container {
    width: 100%;
  }

  .reactPaginate {
    width: 100%;
    justify-content: space-between;
  }
}

.prevButtonIphone{
  transform: rotate(90deg);

}

.nextButtonIphone{
  transform: rotate(270deg);
}