@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.propertyData {
  > section {
    padding-top: 24px;
    padding-bottom: 20px;
    border-top: 1px solid $gray13;
  }

  > .breadcrumb {
    margin: 22px 0px;
  }

  @include screen(min-lg) {
    .card {
      display: none;
    }
  }

  @include screen(max-md) {
    section {
      margin-left: 10px;
      margin-right: 10px;
    }

    .card {
      position: relative;
    }

    .breadcrumb {
      display: none;
    }
  }
}
