@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.visitScheduleModal {
  display: flex;
  flex-direction: column;
  margin: 9px 14px;
  zoom: 1.025;

  > .header {
    display: flex;
    position: relative;
    width: 100%;

    > .info {
      display: flex;
      flex-direction: column;

      .contactText {
        margin-top: -2px;
      }

      .description {
        margin-top: 14px;
      }
    }

    > .logo {
      margin: 0;
      position: absolute;
      right: 25px;
      top: 0;
      max-width: 120px;
      max-height: 58px;
    }
    @include screen(xs) {
      flex-direction: column-reverse;
      > .logo {
        margin: 0;
        top: 0;
        position: unset;
        width: 150px;
        align-self: center;
      }
    }
  }

  > .main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 29px;
    position: relative;

    > .inputName {
      width: 100%;
      margin-top: 17px;
    }

    > .inputPhone,
    > .inputEmail {
      margin-top: 3px;
    }

    > .selectPeriod,
    > .selectDay {
      margin-top: 6px;
    }

    > .inputPhone,
    > .selectPeriod {
      width: 50%;
      padding-left: 3%;
      box-sizing: border-box;
    }

    > .inputEmail,
    > .selectDay {
      width: 50%;
      padding-right: 2%;
      box-sizing: border-box;
    }

    .optins {
      margin-top: 25px;
    }
    input {
      height: 40px;
    }

    > .inputName,
    > .inputPhone {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 0;
        font-size: $font-size-12;
      }
    }

    > .inputEmail {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 11px;
        font-size: $font-size-12;
      }
    }

    @include screen(max-sm) {
      .selectPeriod {
        width: 100%;
        padding-left: 0;
      }
      .selectDay {
        width: 100%;
      }
    }

    .warning {
      position: absolute;
      bottom: -5%;
      color: $tertiary-color;
      font-size: $font-size-14;
      left: -2px;

      @include screen(xs) {
        position: static;
        max-width: 40ch;
        > p {
          margin-left: 0;
        }
      }
    }
  }

  > .footer {
    justify-content: center;
    display: flex;
    margin-top: 2%;

    .button {
      @include screen(max-sm) {
        margin-top: 16px;
      }
    }
  }
}
