@import '../../assets/scss/mixins.scss';

.valuesContainer {
  @include flexBox(column, space-between, space-between);
  &.activeCampaign {
    > [class*='discount'] {
      order: 2;
    }

    > .promotionPrice {
      order: 3;
    }
  }
  p[class*='percent'] {
    font-size: 24px;
  }
  > span[class*='saleValueText'] {
    > p {
      font-size: 16px;
    }
    > strong {
      font-size: 24px;
    }
  }
  > strong {
    font-size: 24px;
    &[class*='campaign'] {
      font-size: 16px;
    }
  }
  div[class*='row'] {
    > p {
      font-size: 16px;
    }
  }
}
