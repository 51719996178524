@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
  font-size: $font-size-14;

  > .buttonReturn {
    display: flex;
    background: inherit;
    border: 0;
    align-items: center;
    text-decoration: none;
    &:focus {
      outline: 0;
    }

    .arrowIOS {
      transform: rotate(90deg);
    }
  }
}

.breadcrumb {
  display: flex;
  width: 100%;
  max-width: 700px;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    > a {
      outline: none;
      text-decoration: none;
      color: $text-black-color;
      white-space: nowrap;
    }
  }
  .lastStep {
    a {
      outline: none;
      text-decoration: none;
      color: $primary-color;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    overflow: hidden;
    max-width: 450px;
  }
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: $gray8;
  border-radius: 50%;
  display: inline-block;
  margin: 0 12.5px;
}

.separator {
  display: inline-block;
  margin: 0 5px;
}

.back {
  cursor: pointer;
  outline: none;
  color: $primary-color;
  font-size: $font-size-14;
  font-weight: 600;
}
