@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.largeButton {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;

  @include screen(max-md) {
    width: 98%;
  }

  > svg {
    margin-right: 2%;
  }
}
