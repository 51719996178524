@import '../../assets/scss/mixins.scss';

.estateHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  overflow-x: hidden;

  .headerButtons {
    display: flex;
    margin-top: 15px;
  }

  @include screen(max-sm) {
    flex-direction: column;
    margin-bottom: 0px;

    .headerButtons {
      margin-bottom: 15px;
      align-items: center;
    }
  }

  @include screen(xs) {
    .headerButtons {
      margin-bottom: 15px;
      width: 100%;
      gap: 15px;

      button,
      span {
        width: 50%;

        select {
          width: 96%;
        }
      }
    }
  }
}
