.modalContainer {
  height: 80vh;

  &.btnWhite {
    [class*='clear'] > button {
      margin: 24px 0 0 -4px;

      use {
        fill: #ffffff;
      }
    }
  }

  [class*='clear'] {
    top: 32px;
    left: 32px !important;
    cursor: pointer !important;
  }

  &.pipefy {
    width: 94vw;
    max-width: 760px;
    height: 90vh;
    overflow: hidden;

    [class*='clear'] {
      top: 18px;
      left: unset !important;
      right: 32px !important;
    }
  }

  .formContainer {
    height: 100%;
  }

  .form {
    height: 100%;
  }
}
