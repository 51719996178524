@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.wrapper {
  margin: 9rem auto 9rem;
  text-align: center;
}

.divider {
  flex: 0 0 2px;
  align-self: stretch;
  background: $gray12;
  margin: 0 4rem;
}

.code {
  font-size: 25vw;
  color: $primary-color;
  font-weight: bold;
  width: 100%;
}

.content {
  flex: 0 1 350px;
  min-width: 270px;
  h1 {
    font-size: $font-size-36;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 12px;
  }
  ul {
    margin-top: 18px;
  }
  li {
    margin: 0 0 0.75em;
  }
  a {
    text-decoration: none;
    font-weight: bold;
    color: $text-black-color;
  }
  .link {
    color: white;
    margin-top: 20px;
    min-width: 80%;
    max-width: 90%;
    display: block;
    height: auto;
    font-size: $font-size-16;
    padding: 0.75em 0.85em;
    text-align: center;
  }
}

@include screen(min-md) {
  .wrapper {
    text-align: left;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 850px;
  }
  .code {
    font-size: 10vw;
  }
}

@include screen(max-md) {
  .wrapper {
    margin: 5rem 1.7rem;
  }
  .content {
    min-width: 100%;
  }
}

.seeMoreButton {
  margin-top: 10px;
}
