@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.propertyBannerWrapper {
  .buttonReturn {
    display: flex;
    background: inherit;
    border: 0;
    align-items: center;
    text-decoration: none;
    position: absolute;
    z-index: 1;
    &:focus {
      outline: 0;
    }

    > span > svg {
      filter: drop-shadow(1px -2px 2px rgba(0, 0, 0, 0.7));
    }
  }

  .tagOverBanner {
    position: absolute;
    padding: 12px;
    color: white;
    z-index: 1;
  }

  .iphone {
    transform: rotate(90deg);
  }

  .contentArea {
    position: relative;
    width: 100%;

    > div {
      display: none;
    }

    .clockIcon {
      z-index: 1;
      position: absolute;
      border-radius: 50%;
      padding: 30px 38px;

      background-color: rgba(0, 0, 0, 0.4);

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .active, .activeVideo {
      display: block;
      height: 555px;
    }
  }

  .buttonsArea {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }

  @include screen(min-lg) {
    .buttonReturn {
      display: none;
    }
  }

  @include screen(max-md) {
    .buttonReturn {
      top: 80px;
    }

    .contentArea {
      .active {
        max-height: 500px;
      }
    }
  }

  @include screen(xs) {
    .buttonReturn {
      top: 60px;
    }

    .contentArea {
      .active {
        max-height: 280px;
      }
    }
  }
}
