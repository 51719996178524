@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.terms {
  text-align: justify;
  justify-content: space-evenly;
  display: flex;

  .container {
    width: 70%;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .linksContainer {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    .link {
      text-decoration: none;
      color: $blue3;
      margin-bottom: 20px;
      font-size: $font-size-18;
      cursor: pointer;

      &:hover {
        color: $primary-color;
      }
    }

    .activeLink {
      font-weight: bold;
      text-decoration: none;
      color: $blue3;
      margin-bottom: 20px;
      font-size: $font-size-18;
      cursor: pointer;
    }
  }

  p {
    margin-bottom: 10px;
  }

  .initialText {
    margin-bottom: 20px;
  }

  .contactText {
    margin-top: 50px;
  }

  @include screen(max-md) {
    flex-direction: column;
    padding: 50px 30px;

    .container {
      width: 90%;
    }
  }
}
