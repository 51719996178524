@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.valuesContainer{
	box-sizing: border-box;
	margin-top: 1rem;
	background-color: #f4f4f4;
	border-radius: 8px;
	width: 100%;
	padding: 16px 24px;

	p{
		@include flexBox(row, space-between);
		margin-top: 4px;
		font-size: 16px;
		line-height: 24px;
		color: #666666;
    font-weight: 300;

		&:first-child{
			margin-top: 0;
		}
		&.highlighted{
			font-weight: 700;
		}
		&.interest{
			color: #666666;
			font-weight: 300;

		}
		&.footNote{
			display: block;
			text-align: right;
			align-self: flex-end;
			color: #666666;
			font-weight: 300;
			font-size: 12px;

		}
		&.hrbar{
			display: inline-block;
			width: 100%;
			font-weight: 300;
			margin-bottom: 0;
			height: 2px;
			border-color: inherit;

		}
	}
}