@import '../../assets/scss/mixins.scss';
.formContainer {
  height: 700px;
  margin-bottom: 48px;
  .form {
    @include flexBox();
    width: 100%;
    height: 100%;
  }
}

.breadCrumb {
  margin: 24px 0;
}
