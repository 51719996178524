@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.home {
  margin-bottom: 20px;
}

.buttonContainer {
  display: none;
}

.bannerContainer {
  line-height: 0;
}

@include screen(max-sm) {
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 30px;
    width: 100%;
    background-color: transparent;

    .linkOpportunity {
      text-decoration: none;
      .buttonOpportunity {
        align-self: center;
        box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.25);
        font-size: $font-size-18;
        height: 50px;
      }
    }
  }
}
