@import '../../assets/scss/variables.scss';

.primaryColor {
  fill: $primary-color;
}

.darkColor {
  fill: $gray9;
}

.grayColor {
  fill: $gray8;
}

.lightGrayColor {
  fill: $gray15;
}

.whiteColor {
  fill: $white-color;
}

.secondColor {
  fill: $secondary-color;
}

.rotate {
  transform: rotate(135deg);
}

svg {
  cursor: unset !important;
}
