@import '../../assets/scss/variables.scss';

.formText {
  line-height: 1.31;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
}

.formButton {
  margin-top: 24px;
  padding: 0px 30px;
  width: 120px;
}

.buttonAlign {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.main {
  margin-top: 30px;

  .inputEmail {
    input {
      height: 40px;
    }
  }

  .optins {
    margin-top: 25px;
  }
}
