@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

main {
  .noBorder {
    border-top: none;
    padding-top: 0px;
    padding-bottom: 12px;

    @include screen(max-md) {
      margin: 0;
    }
  }
}
