.addressSection {
  .propertyIdText {
    margin-top: 12px;
  }

  .tags {
    display: flex;
    flex-flow: wrap;
  }
}
