@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

.successContainer{
	@include flexBox(column,);
	height: 100%;

	@include screen(xs){
		padding: 0;
	}

	@include screen(xs){
		margin: -3em 0;

		&.noModal {
			margin: 20px 10px;
		}
	}


	> h2 {
		margin-top: 1rem;
		font-weight: 700;
		text-align: center;

	}
	> p {
		text-align: center;
		margin-top: 1rem;
	}

	button {
		max-width: 280px;
		width: 100%;
		margin-top: 1rem;
    height: 52px;
		color: $white-color;
		border-radius: 8px;
		background-color: #FB4E5F;
		border-color: #FB4E5F;
	}
}