@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';

form {
  hr {
    border-top: 1px $gray2 solid;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.input {
  font-size: $font-size-12;
}

.option {
  background: $white-color;
  height: 38px;
  padding: 0px 11px;

  border: 1px solid $gray4;
  border-width: 1px;
  border-radius: 0;
  border-color: $gray4;

  border-top: none;
  border-bottom: none;

  &:last-child {
    border-bottom: 1px solid $gray4;
  }

  box-sizing: border-box;
  outline: 0;
  width: 100%;
  max-width: 300px;
}

.title {
  margin-bottom: 10px;
  color: $primary-color;
}

.linkLike {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  border: 0;
  color: $primary-color;
  background: none;
  font-size: $font-size-16;
  height: 20px;
  position: absolute;
  right: 22px;
  margin-top: 32px;

  > span {
    margin-left: 4px;
  }

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }
}

button.showAdvancedFiltersButton {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $tertiary-color;

  @extend .linkLike;

  width: 100%;
  margin-bottom: 24px;
  position: static;
}

.arrowClose {
  transform: rotate(270deg);

  @include iOS {
    transform: rotate(0deg);
  }
}

.arrowOpen {
  transform: rotate(90deg);
  margin-top: 3px;
  @include iOS {
    transform: rotate(180deg);
  }
}

.mobileFilters {
  padding: 0 5px;
  @include screen(min-sm) {
    display: none;
  }
}

.desktopFilters {
  &:first-child {
    section {
      &:nth-child(3) {
        margin-bottom: 22px;
      }
    }
  }
  @include screen(xs) {
    display: none;
  }
}

.hideOnDesktop {
  @include screen(min-sm) {
    display: none;
  }
}
