@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.messageForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .text {
    margin-top: -2px;
    margin-bottom: 10px;
  }

  > .main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 13px;

    > .inputName {
      width: 100%;
      margin-top: 5px;
      height: 73px;

      input {
        height: 40px;
      }
    }

    > .inputPhone {
      margin-top: 5px;
      width: 100%;
      box-sizing: border-box;
      input { height: 40px;}
    }

    > .inputEmail {
      margin-top: 5px;
      width: 50%;
      padding-right: 2%;
      box-sizing: border-box;

      input {
        height: 40px;
      }
    }

    > .inputSubject {
      width: 100%;
      margin-top: 6px;

      > span {
        font-size: 14px;
      }
    }

    > .inputMessage {
      width: 100%;
      margin-top: 5px;
      height: 155px;

      textarea {
        resize: none;
        padding: 10px 11px;
        height: 120px;
      }

      > span {
        bottom: -16px !important;
      }
    }

    .modalLabel {
      margin-bottom: 3px;
    }

    > .inputPhone,
    > .inputMessage {
      position: relative;

      > span {
        position: absolute;
        bottom: -19px;
        right: 0;
      }
    }

    > .inputEmail {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 11px;
      }
    }
  }

  .button {
    width: 120px;
  }

  .buttonAlign {
    margin-top: 8px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}
