@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.title {
  font-size: $font-size-36;
  margin-bottom: 50px;
  margin-left: -30px;
  @include screen(max-md) {
    text-align: center;
    margin-left: 0;
  }
}

.contactPage {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 10px 50px;
  justify-content: center;

  @include screen(max-md) {
    margin: auto 7%;
    flex-direction: column-reverse;
  }

  .image {
    max-width: 605px;
    max-height: 415px;
    height: 95%;
    margin-left: 15px;

    @include screen(max-md) {
      max-width: 505px;
      max-height: 315px;
      margin-top: 20px;
    }
  }

  .form {
    max-width: 439px;
    margin: 64px 5%;
    @include screen(max-sm) {
      max-width: none;
      padding: 0 20px;
      margin: 64px auto 51px;
    }
  }

  .text {
    margin-top: 51px;
    text-align: center;
  }

  .list {
    margin-bottom: 64px;
  }
}
