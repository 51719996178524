@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.advertising {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10%;

  img {
    max-width: 270px;
    margin-bottom: 100px;

    @include screen(xs) {
      width: 100%;
      max-width: 100%;
    }
  }
}
