@import '../../assets/scss/variables.scss';

.text {
  margin: 0px;
  white-space: normal;
  word-break: break-word;
}

.primaryColor {
  color: $primary-color;
}

.darkBlueColor {
  color: $blue3;
}

.lightTextColor {
  color: $gray15;
}

.blackColor {
  color: $text-black-color;
}

.resaleGreenColor {
  color: $primary-color;
}

.goldenColor {
  color: $yellow-color;
}
.gunmetalColor {
  color: $gunmetal-color;
}

.font-xxsmall {
  font-size: $font-size-10;
}

.font-xsmall {
  font-size: $font-size-12;
}

.grayColor {
  color: $gray8;
}

.whiteColor {
  color: $white-color;
}

.font-small {
  font-size: $font-size-14;
}

.font-medium {
  font-size: $font-size-16;
}

.font-regular {
  font-size: $font-size-18;
}

.font-large {
  font-size: $font-size-20;
}

.font-xlarge {
  font-size: $font-size-24;
}

.bold {
  font-weight: 600;
}

.textCenter {
  text-align: center;
}
