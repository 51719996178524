@import '../../assets/scss/variables.scss';

.partnerButton {
  font-weight: 500;
  margin: 36px 1.5vw;
  width: 280px;
  height: 280px;
  background-color: $gray0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 22px;

  use {
    fill: $text-black-color;
  }

  .icon {
    margin: 0px 0px 16px;
  }
}
