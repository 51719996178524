@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.maintenanceContainer {
  box-sizing: border-box;
  background-color: #004a3d;
  position: relative;
  padding: 16px;
  width: 100%;
  z-index: 1;
  &.closed {
    display: none;
  }
  > .headerNotification {
    @include flexBox(row, space-between, center);
    margin-bottom: 8px;

    > .maintenance {
      height: 100%;
      width: 150px;
      margin-bottom: -16px;
      margin-top: -8px;
      margin-right: 20px;

      @media (min-width: 800px) and (max-width: 962px) {
        margin-bottom: -29px;
      }
    }

    > .titleContainer {
      > .subTitle {
        font-weight: 400;
        font-size: $font-size-16;
        line-height: 30px;
        color: $white-color;
        align-self: center;
      }

      > .title {
        font-weight: 600;
        font-size: $font-size-18;
        line-height: 20px;
        color: $white-color;
        align-self: center;
      }
    }

    > .iconContainer {
      @include flexBox();
      width: 20px;
      height: 20px;
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        :hover {
          filter: opacity(0.8);
          transition: all 400ms ease-in-out;
        }
        img {
          align-self: flex-start;
        }
      }
      @media (min-width: 800px) {
        display: none;
      }
    }
  }
  > .info {
    color: $white-color;
    font-size: $font-size-16;
    line-height: 20px;
    a {
      color: $white-color;
      font-size: $font-size-16;
    }

    @media (max-width: 800px) {
      margin-top: 25px;
    }
  }
  > .iconContainerBig {
    display: none;

    @media (min-width: 800px) {
      display: inline-block;
      align-self: flex-start;
      position: absolute;
      right: 4px;
      top: 4px;
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        :hover {
          filter: opacity(0.8);
          transition: all 400ms ease-in-out;
        }
        img {
          align-self: flex-start;
        }
      }
    }
  }
}

@media (min-width: 800px) {
  .maintenanceContainer {
    @include flexBox(row, flex-start, flex-start);
    padding: 16px 64px 8px;
    max-height: 140px;
  }
  .divider {
    border-right: 2px solid #3d5992;
    height: 32px;
    display: inline-block;
    margin: 0 32px;
  }
  .info {
    max-width: calc(60% - 5vw);
    justify-self: center;
    align-self: center;
  }
}
