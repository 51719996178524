@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.wrapper {
  position: relative;

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;

  @include screen(xxl) {
    max-width: 600px;
    white-space: nowrap;
  }

  @include screen(xl) {
    max-width: 500px;
    white-space: nowrap;
  }

  @include screen(lg) {
    white-space: nowrap;
    max-width: 350px;
  }

  @include screen(max-md) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    @-moz-document url-prefix() {
      height: 22px;
      line-height: 1.4em;
      overflow: hidden;
      position: relative;
    }
  }
}

.tooltip {
  position: absolute;
  top: 0;
  visibility: hidden;
  background: #fff;
  color: $text-black-color;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 12px;
  word-break: normal;
  max-width: 400px;
  font-size: $font-size-14;
  z-index: 4;

  & > p {
    color: $text-black-color;
  }
}
