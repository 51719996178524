@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.discount {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .percent {
    color: $secondary-color;
    font-weight: 700;
    margin: 0;
    margin-left: 20px;
  }

  .percent.medium {
    font-size: $font-size-18;
  }

  .percent.large {
    font-size: $font-size-24;
  }

  .percent.regular {
    font-size: $font-size-18;
  }

  span {
    display: inline-flex;
    align-items: center;
  }
}
