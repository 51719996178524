@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  position: relative;

  @media (max-width: 766px) {
    width: 94vw;
  }

  button[class*='react-multiple-carousel__arrow'] {
    box-shadow: 0px 15px 20px rgba(197, 197, 197, 0.4);
    color: #fff9f9 !important;
    margin: 0 auto;
    transition: all 0.3s ease 0s;
    top: 50%;
    background-color: #f9f9f9 !important;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    z-index: 1;

    &::before {
      color: $black-color;
      font-size: 12px;
      text-align: center;
      margin-top: 2px;
    }
    &:hover {
      color: #111111 !important;
    }
  }

  div[class*='magic-dots'] {
    position: absolute !important;
    bottom: 4% !important;
    margin: 0 auto;

    > ul {
      > li {
        button {
          &::before {
            opacity: unset;
            color: $white-color;
          }
        }
      }

      > li[class*='active'] {
        button {
          &::before {
            color: $primary-color;
          }
        }
      }
    }
  }

  div[class*='slick-slider'] {
    display: flex;
    justify-content: center;

    img {
      object-fit: cover;
    }
  }

  div[class*='slick-list'] {
    width: 100%;
  }

  div[class*='slick-slide'] {
    > div {
      &:first-child {
        width: 100%;
        height: 210px;
      }
    }

    @media (min-width: 1024px) and (max-width: 1142px) {
      > div {
        &:first-child {
          height: 242px;
        }
      }
    }
  }
}
