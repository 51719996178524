@import '../../assets/scss/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  // max-width: 256px;
	> .close {
		position: absolute;
		padding: 0;
		border: 0;
		background: none;
		cursor: pointer;
		top: 4px;
		right: 13%;
		z-index: 2;
		color: $primary-color;

		&:disabled{
			display: none;
		}
	}

  input {
    border: 1px solid #545460 !important;
    border-radius: 16px !important;
  }
}

svg {
  cursor: pointer;
}
