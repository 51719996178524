@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: $primary-color;
  border-radius: 8px;

  .figure {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;

    .imgContainer {
      display: flex;
      justify-content: center;
      align-content: center;
      margin-top: -$notification-box-rosie-size / 2;
      width: $notification-box-rosie-size - 2px;
      height: $notification-box-rosie-size - 2px;
      border: 5px solid $white-color;
      border-radius: 50%;

      .img {
        width: $notification-box-rosie-size;
        height: $notification-box-rosie-size;
      }
    }
  }

  .body {
    @include flexColumnCenter;
    align-items: center;
    width: 100%;

    > p {
      margin-top: 15px;
    }

    .info {
      @include flexColumnCenter;
      text-align: center;
      padding: 15px;
      margin: 0% 10%;

      .emailInput {
        width: 100%;
        height: 37px;
        margin-top: 8px;

        label {
          margin: 0;
        }

        span {
          font-size: $font-size-14;
        }

        input {
          border-radius: 5px;
        }
      }

      .buttonSubmit {
        width: 120px;
        margin-top: 35px;
        margin-bottom: 15px;
      }

      @include screen(xs) {
        margin: 0% 1%;
      }
    }
  }
}
