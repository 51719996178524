@import '../../assets/scss/variables.scss';

.auctionDate {
  display: flex;
  align-items: flex-end;
  height: 24px;

  p {
    margin-left: 5px;
  }
}
