@import '../../assets/scss/variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;

  > p {
    margin-top: -3.5px;
  }

  > .toggle {
    width: 30px;
    height: 20px;
  }
}

.option-left,
.option-right-aligned {
  flex-direction: row;
}

.option-right {
  flex-direction: row-reverse;
}

.option-right-aligned {
  justify-content: flex-end;

  > p {
    margin-right: 16px;
  }
}
