@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$small-screen: 768px;

.card {
  display: inline-flex;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  margin-bottom: 14px;
  width: 100%;

  a {
    text-decoration: none;
    position: relative;
  }

  > .image {
    min-width: 200px;
    max-width: 200px;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin: 0;
    cursor: pointer;
    position: relative;
    object-fit: cover;

    &.isCarousel {
      > a {
        height: 100%;
        > div {
          height: 100%;
          > div {
            height: 100%;
            > ul {
              &:first-child {
                height: 100%;
              }
            }
          }
        }
      }
    }

    .preActiveTag {
      position: absolute;
      z-index: 1;
      top: 3%;
      left: 3%;

      > span {
        height: 18px;
      }
    }
  }
  > .information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 20px;
    width: 100%;
    max-width: 80%;
    min-width: 30%;

    @include screen(lg) {
      padding: 13px 6px;
    }

    .propertyTitle {
      font-size: $font-size-18;
      font-weight: 600;
      color: $blue3;
    }

    > .tags {
      margin-bottom: 2px;
      display: flex;
      flex-flow: wrap;
    }

    > .features {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
      > div {
        margin-right: 6px;
      }
    }
  }

  > .values {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    max-width: 280px;
    min-width: 200px;
    border-left: 1px solid $gray1;

    
    @include screen(lg) {
      min-width: 150px;
      padding: 6px;

      > .prices {
        gap: 10px;
        div {
          flex-direction: column;

          p {
            font-size: 14px;
          }
        }
        span {
          flex-direction: column;
          align-items: flex-start;
          p, strong {
            font-size: 16px;
          }
        }
      }
    }

    > .prices {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      margin-bottom: 5px;

      &.activeCampaign {
        > [class*='discount'] {
          order: 1;
        }

        > .promotionPrice {
          order: 2;
        }
      }
    }

    .buttonOpportunity > .button {
      width: 100%;
    }
  }

  .mobileNavigate {
    display: none;
  }

  @include screen(max-md) {
    .mobileNavigate {
      display: flex;
      position: absolute;
      // width: 91%;
      height: 515px;
    }

    flex-direction: column;
    width: auto;

    .tags {
      max-width: 300px;
      flex-wrap: wrap;
      display: flex;
    }

    > .image {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
      height: 214px;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    > .information {
      border-bottom: 0.1px solid $gray13;
      padding-bottom: 10px;
      margin-bottom: 10px;
      width: auto;

      > .features {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    > .values {
      max-width: none;
      width: 90%;
      align-self: center;
      flex-direction: column;
      justify-content: center;
      padding-top: 0px;
      padding-bottom: 0px;
      border: none;

      > .prices {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;

        div {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;

          p {
            font-size: $font-size-16;
          }
        }
        .discount {
          width: 100%;
        }
      }

      > .buttonOpportunity {
        display: none;
      }
    }
  }
}

.resumeAddress {
  color: $text-black-color;
  font-size: $font-size-14;
  margin-bottom: 2px;
  letter-spacing: 0.18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}

.ellipsis {
  font-size: $font-size-14;
  color: $text-black-color;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: initial;

  @-moz-document url-prefix() {
    height: 42px;
  }

  @include screen(max-md) {
    margin-top: 0.5em;
  }
}

.link {
  text-decoration: none;
  color: $primary-color;
  font-weight: bold;
  font-size: $font-size-18;
}

.sold {
  & span {
    background-color: $secondary-color;
    color: $white-color;
    width: 100%;
    font-size: $font-size-14;
    font-weight: bold;
    letter-spacing: 0.9px;
  }

  & div {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    display: flex;
    top: 10%;
    left: -30%;
    padding: 5px 0;
    text-align: center;
    transform: rotate(-45deg);
    height: 25px;

    @include screen(xs) {
      top: 8%;
      left: -25%;
      width: 80%;
    }

    @include screen(max-sm) {
      width: 80%;
    }

    @include screen(min-md) {
      width: 80%;
    }

    @include screen(max-md) {
      width: 75%;
    }

    @include screen(min-lg) {
      width: 100%;
    }
  }
}
