@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.banner {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 481px) {
   max-width: 375px;
  }

  @media (max-width: 481px) {
   max-width: 375px;
  }

  img {
    width: 100%;
  }
}