@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color;
  height: 71px;
  padding-left: 55px;
  padding-right: 45px;

  @include screen(max-sm) {
    height: 60px;
  }
}

.simulatorHeader {
  display: table-column;
}

.logo {
  width: 200px;
  height: 70px;
  display: flex;

  > .logoImgPointer {
    width: 200px;
  }

  > .logoImg {
    cursor: pointer;
    width: 200px;
    image-rendering: -webkit-optimize-contrast;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
}

.listItem {
  display: inline;
  padding-left: 20px;

  a {
    color: $white-color;
    text-decoration: none;
  }
}

.link {
  color: $white-color;
  font-size: $font-size-16;
  text-decoration: none;
}

.linkActive {
  color: $secondary-color;
  text-decoration: underline;
  font-weight: 600;
}

.burger {
  display: block;
  width: 25px;
  border: none;
  background: transparent;
  position: relative;
  z-index: 3;
  outline: none;
  padding: 8px 0;
  > div,
  &::after,
  &::before {
    content: '';
    align-self: stretch;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background: $white-color;
    transition: 0.3s ease;
    transform-origin: center;
  }
  &::after,
  &::before {
    position: absolute;
    left: 0;
  }
  &::after {
    bottom: 0;
  }
  &::before {
    top: 0;
  }
}

.burgerOpen {
  > div {
    opacity: 0;
  }
  &::after,
  &::before {
    background: $primary-color;
  }
  &::before {
    transform: rotate(-45deg) translateY(-30%);
    top: 50%;
  }
  &::after {
    transform: rotate(45deg) translateY(-30%);
    top: 50%;
    bottom: auto;
  }
}

@include screen(min-md) {
  .burger {
    display: none;
  }
}

@include screen(max-sm) {
  .navbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $white-color;
    z-index: 2;
    display: none;
    a {
      color: $primary-color;
      text-decoration: none;
    }
  }

  .list {
    width: 90%;
    padding: 0;
    margin: 2.5em;
    text-align: left;
  }

  .listItem {
    margin: 0 0 0.9em;
    font-size: $font-size-24;
    display: block;
  }

  .link {
    color: $text-black-color;
    font-size: inherit;
  }

  .navbarOpen {
    display: flex;
    align-items: flex-start;
  }

  .logo {
    flex: 1;

    > .logoImg {
      margin-top: 10px;
      width: 150px;
      height: 50px;
    }
  }

  .header {
    justify-content: flex-start;
    padding: 0 16px;
  }

  .burger {
    display: flex;
  }
}
