@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.span {
  color: $primary-color;
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;

  &.campaign {
    text-decoration: line-through;
    font-size: 0.95em;
    color: $text-black-color;
    font-weight: normal;
  }

  @include screen(xs) {
    font-size: 1.4em;
  }
}

.font-medium {
  font-size: $font-size-16;
}

.font-regular {
  font-size: $font-size-18;
}

.font-large {
  font-size: $font-size-20;
}

.font-xlarge {
  font-size: $font-size-24;
}
