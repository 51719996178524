@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.leadModal {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 18%;

  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 13px;
    padding-left: 15px;
    padding-right: 15px;

    .question {
      text-align: center;
      margin-bottom: 10px;
    }

    .inputEmail {
      input {
        height: 40px;
      }
    }

    .optins {
      margin-top: 10px;

      > div {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid $gray13;
    margin-top: 10%;

    .button {
      width: 100%;
      height: 50px;
      margin-top: 16px;
    }
  }

  @include screen(xs) {
    height: 85%;
    justify-content: space-between;
    padding-top: 5%;

    .footer {
      margin-top: 5%;
    }
  }

  @include screen(xxs) {
    height: auto;
  }

  .boldText {
    color: $white-color;
  }
}

.modalLead {
  width: 380px;

  #closeModal {
    margin-top: 5em !important;
  }

  @include screen(xs) {
    width: 90%;
    height: 98%;
  }
}
