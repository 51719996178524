@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.main {
  margin: 62px auto 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .title {
    color: $text-black-color;
  }

  .partnershipButtons {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    @include screen(max-md) {
      display: flex;
      flex-direction: column;
    }
  }

  .horizontalLine {
    border: none;
    width: 100%;
    border-top: 1px solid $gray3;
    margin: 20px 30px 48px;
    max-width: 1050px;
  }
}

.containerDepoiments {
  .titleDepoiments {
    color: $text-black-color;
    margin-bottom: 32px;
    text-align: center;
  }

  .depoiments {
    margin-left: 36px;
    @include screen(xxs) {
      margin-left: unset;
    }
    @include screen(xs) {
      > div {
        > ul {
          padding: 0px 18px;
        }
      }
    }
  }
}

.formContainer {
  height: 900px;
  margin-bottom: 48px;

  .clearForm {
    background-color: transparent;
    width: 25px;
  }

  .form {
    @include flexBox();
    width: 100%;
    height: 100%;
  }
  
  .iframeForm {
    width: 100%;
    min-height: 100%;
  }
}
.breadCrumb {
  margin: 24px 0;
}
