@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.text {
  margin: 0px;
  margin-right: 20px;
  white-space: normal;
  word-break: break-word;

  @include screen(xs) {
    word-break: normal;
    margin-bottom: 1em;
  }
}

.darkBlueColor {
  color: $blue3;
}

.lightTextColor {
  color: $gray15;
}

.primaryColor {
  color: $primary-color;
}

.font-small {
  font-size: $font-size-14;
}

.font-medium {
  font-size: $font-size-16;
}

.font-large {
  font-size: $font-size-18;
}

.bold {
  font-weight: 600;
}

.mobile {
  font-weight: 600;
  color: $blue3;
  width: 100%;
}

@media(max-width: 950px) {
  .desktop {
    display: none;
  }
}

@media (min-width: 951px) {
  .mobile{ 
    display: none;
  }
}
