@import '../../assets/scss/variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 5px 0px;
}

.option-left,
.option-left-joined {
  padding-left: 25px;
  flex-direction: row;
}

.option-right,
.option-right-joined {
  padding-right: 25px;
  flex-direction: row-reverse;
}

.option-right-joined,
.option-left-joined {
  justify-content: flex-end;
  padding-right: 0px;
}

.option-terms {
  justify-content: flex-end;
  flex-direction: row-reverse;
  height: 27px;
}

.container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  left: -3px;
}

.checkmark {
  height: 14px;
  width: 14px;
  background-color: $white-color;
  border: 1.4px solid $gray15;
  border-radius: 4px;
}

.container input:checked ~ .checkmark {
  background-color: $secondary-color;
  border: 1.4px solid $secondary-color;
}

.checkmark:after {
  content: '';
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  margin: 2.2px 4.5px;
  width: 2.5px;
  height: 6px;
  border: 0.1px solid $white-color;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
