@import '../../../assets/scss/variables.scss';

.wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #fff;
  margin: 0 0 16px;
  padding: 20px 24px;
}

.content {
  opacity: 0;
  transition: 0.3s 0.1s opacity ease;
  height: 0;
  overflow: hidden;
  > p {
    display: block;
    font-size: $font-size-16;
    margin-bottom: 1.25em;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  b {
    font-weight: bold;
  }
  a {
    color: inherit;
  }
}

.button {
  border: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  text-align: left;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  > h2 {
    flex: 1;
  }
  > svg {
    width: 24px;
    color: $secondary-color;
    transition: color 0.3s ease;
  }
  rect {
    fill: currentColor;
  }
  circle {
    stroke: currentColor;
  }
}

.open {
  > .content {
    height: auto;
    opacity: 1;
    padding-top: 1rem;
  }
  svg {
    color: $secondary-color;
  }
  h2 {
    color: $primary-color;
  }
}
