@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.searchHeader {
  padding: 4px 12px 12px;
  background-color: $primary-color;
  display: flex;
  justify-content: center;

  .inputContainer {
    width: 70%;

    svg {
      margin-top: 6px;
      width: 18px;
      height: 18px;
      color: $blue3;
    }
  }

  .searchInput {
    height: 40px;
    border-radius: 7px;
  }

  @include screen(max-sm) {
    padding-top: 0px;

    .inputContainer {
      width: 100%;
    }
  }
}
