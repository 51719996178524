@import '../../assets/scss/variables.scss';

.container {
  > .rangeSlider {
    padding: 0 8px 0 6px;
    margin-top: 8px;
  }
}

.inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 13px;

  > div:nth-child(1),
  > div:nth-child(3) {
    width: 120px;
  }

  > p {
    color: $lavender0;
    line-height: 32px;
  }
}
