@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

@mixin noModal {
  position: static;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.simulatorContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  zoom: 0.9;
  height: 100%;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  &.noModal {
    max-width: 760px;
    margin: 32px auto;
  }

  // for IE and Edge
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    zoom: 1;
  }

  @include screen(xs) {
    padding-bottom: 5%;
    @supports (-webkit-touch-callout: none) {
      margin-top: 29px;
    }
  }

  .top {
    @include flexBox();

    > .title {
      color: #666666;
      line-height: 26px;
      font-weight: 300;
    }
  }

  > .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 3;
    padding: 1em 0;

    &.noModal {
      @include noModal();
    }

    @include screen(xs) {
      padding: 2em 0;
    }

    .info {
      display: flex;
      flex-direction: column;

      .description {
        margin-top: 14px;
      }
    }
  }

  > .main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 29px;
    position: relative;
    max-width: 90%;
    margin: 0 auto;

    .contactText {
      margin-top: 16px;
      color: #262626;
      line-height: 24px;
    }

    .modalLabel {
      line-height: 21px;
      font-weight: 300;
      color: #666666;
      > p {
        font-size: 14px;
      }
    }
    input {
      color: #262626;
    }
    > .inputName {
      width: 100%;
      margin-top: 12px;
      > div {
        display: flex;
      }
    }

    > .inputPhone,
    > .inputEmail {
      margin-top: 3px;
      > div {
        display: flex;
      }
    }

    > .selectPeriod,
    > .selectDay {
      margin-top: 6px;
    }

    > .inputPhone,
    > .selectPeriod {
      width: 50%;
      padding-left: 3%;
      box-sizing: border-box;
    }

    > .inputEmail,
    > .selectDay {
      width: 50%;
      padding-right: 2%;
      box-sizing: border-box;
    }
    > .inputSubject {
      width: 100%;
      margin-top: 16px;
      label {
        p,
        select {
          color: #666666;
          font-size: 14px;
          display: flex;
          font-weight: normal;
        }
      }
      span {
        font-size: 12px;
      }
    }

    input {
      height: 40px;
    }

    > .inputName,
    > .inputPhone {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 0;
        font-size: $font-size-12;
      }
    }

    > .inputEmail {
      position: relative;

      > span {
        position: absolute;
        bottom: -16px;
        right: 11px;
        font-size: $font-size-12;
      }
    }
    .valuesContainer {
      margin-top: 1rem;
      background-color: #f4f4f4;
      border-radius: 8px;
      width: 100%;
      padding: 16px 24px;

      p {
        @include flexBox(row, space-between);
        margin-top: 4px;
        font-size: 16px;
        line-height: 24px;

        &:first-child {
          margin-top: 0;
        }
        &.highlighted {
          font-weight: 700;
        }
        &.interest {
          color: #666666;
          font-weight: 300;
        }
        &.footNote {
          display: block;
          text-align: right;
          align-self: flex-end;
          color: #666666;
          font-weight: 300;
          font-size: 12px;
        }
      }
    }

    @include screen(max-sm) {
      .selectPeriod {
        width: 100%;
        padding-left: 0;
      }
      .selectDay {
        width: 100%;
      }
    }
    .warning {
      position: absolute;
      bottom: -5%;
      left: 0;
      color: $tertiary-color;
      font-size: $font-size-14;

      @include screen(xs) {
        position: static;
        max-width: 40ch;
      }
    }
    > .warnMessage {
      margin: 0.5rem 0.25rem;
      font-size: 14px;
      text-align: justify;
      color: #262626;
      strong {
        color: #262626;
      }
    }
    .term {
      all: unset;
      cursor: pointer;
      text-decoration: underline;
      color: #262626;
      padding-inline: 0.25rem;
      font-size: 14px;
    }
    > .pagimovelContainer {
      @include flexBox(column, center, center);
      margin-top: 12%;
      > .pagimovelTitle {
        font-size: 18px;
      }
      > div {
        @include flexBox(row, center);

        @include screen(max-md) {
          flex-direction: column;
        }
        gap: 19%;
        margin-top: 8%;

        @include screen(max-md) {
          margin-top: 18%;
        }
        > a {
          text-decoration: none;
          > img {
            cursor: pointer;
            width: 90%;
            @include screen(max-md) {
              width: 45%;
              margin-bottom: 8%;
            }
          }
        }
      }
    }
  }

  .footer {
    @include flexBox(column);
    margin-top: 2%;
    bottom: 0;
    position: sticky;
    z-index: 3;
    background-color: $white-color;
    width: 100%;
    padding: 0.4em 0 2em;
    &.noModal {
      @include noModal();
      margin-top: 24px;
    }

    @include screen('max-sm') {
      padding: 0.4em 0 2.5em;
    }

    .buttonContainer {
      @include flexBox(row);
      width: 100%;
      margin: 0 1em;
      &.noModal {
        margin: 0;
      }
      > .button {
        width: 100%;
        max-width: 280px;
        margin: 5px;
        border-radius: 8px;
        height: 52px;
        &.noModal {
          display: none;
        }
        @include screen('xs') {
          max-width: 180px;
        }
      }
    }
  }
}
