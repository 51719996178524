@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.mainSearch {
  display: flex;
  align-items: center;
  padding: 17px 50px 0px 50px;

  .container {
    width: 100%;
    flex-direction: column;

    .sectionSearch {
      display: flex;
      justify-content: center;

      .filters {
        max-width: 20%;
      }

      .result {
        padding-left: 32px;
        width: 80%;
      }
    }
  }

  @include screen(max-lg) {
    .sectionSearch {
      .filters {
        min-width: 260px;
      }
    }
  }

  @include screen(max-sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px 0px 20px;

    .container {
      .sectionSearch {
        .filters {
          display: none;
        }

        .result {
          padding-left: 0px;
          width: 100%;
        }

        flex-direction: column;
        padding: 17px 0;
      }
    }
  }

  @include screen(xxl) {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 17px 0px;

    .container {
      width: 1281px;
      align-self: center;
    }
  }
}

.imageClear {
  line-height: 0;
  position: relative;
}

.timer {
  line-height: 1.5;
  position: absolute;
  right: 36%;
  top: 20%;

  @include screen(xxl) {
    right: 39%;
    top: 15%;
  }

  @include screen(xs) {
    top: 54%;
    right: 11%;
  }

  @media (min-width: 481px) {
    @media (max-width: 767px) {
      right: 36%;
      top: 56%;
    }
  }

  @media (min-width: 768px) {
    @media (max-width: 974px) {
      right: 29%;
      top: 3%;
    }
  }

  @media (min-width: 975px) {
    @media (max-width: 1250px) {
      right: 35%;
      top: 10%;
    }
  }

  @media (min-width: 1930px) {
    right: 42%;
  }

  .timerLabel {
    color: $white-color;
    text-align: center;
  }
}

@include screen(min-md) {
  .buttonContainer {
    display: none;
  }
}

@include screen(max-sm) {
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: fixed;
    bottom: 30px;
    width: 100%;
    background-color: transparent;

    .filterButton {
      width: 50%;
      align-self: center;
      border-radius: 30px;
      box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.25);
      font-size: $font-size-18;
      height: 50px;
    }

    &.hide {
      display: none;
    }
  }
}
