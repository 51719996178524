.carousel {
  width: 100%;
  overflow: hidden;

  .button {
    cursor: pointer;
    width: 100% !important;
  }
  .navigator {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
}

