@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .featuredFeatures {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
    margin-bottom: 28px;

    @include screen(xs) {
      > div:nth-last-child(2),
      :nth-last-child(3) {
        display: none;
      }
    }
  }

  .featuresStart {
    justify-content: center;
  }

  .details {
    transition: height 2s linear;
    overflow: hidden;

    .allFeatures {
      border-radius: 5px;
      background-color: $gray14;
      columns: 3;
      break-before: column;
      column-gap: 0px;
      column-width: 140px;
      margin-bottom: 28px;
      padding: 26px;
    }

    summary::-webkit-details-marker {
      display: none;
    }
    > summary {
      text-align: center;
    }
  }

  @include screen(xs) {
    .featuredFeatures {
      justify-content: center;
      align-items: center;
    }

    .details {
      .allFeatures {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
