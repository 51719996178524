@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.bestSellingSection {
  text-align: center;
  padding: 5px 16px;
  max-width: 1390px;
  margin: 16px auto;

  .bestSellingList {
    margin: 5px auto;
    display: flex;

    .highlightedList {
      margin: 32px auto;
      padding: 0;
      width: 100%;

      article[class*='BestSellingProperty_card'] {
        max-width: 290px;
      }

      > button {
        margin: 0 -2.5% !important;
        background-color: white !important;
        border: 1px solid #f0f0f0;
        box-shadow: 0px 15px 20px rgba(197, 197, 197, 0.4);
        color: #333 !important;
        margin: 0 auto;
        transition: all 0.3s ease 0s;
        top: 35%;
        z-index: 1;

        &::before {
          color: #888;
        }
        &:hover {
          background-color: #f4f4f4 !important;
        }
      }
    }
  }

  .buttonOpportunity {
    text-decoration: none;
  }

  @include screen(max-sm) {
    .buttonOpportunity {
      display: none;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
