@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.select {
  padding: 6px 20px 7.5px;
  min-width: 150px;
  margin-right: 8px;
  border-radius: 3px;
  font-size: $font-size-14;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: none;
  white-space: nowrap;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;

  &:focus {
    outline: none;
  }
  > .right {
    margin-left: 10px;
    width: 23px;
    height: 23px;
  }
  > .left {
    margin-right: 10px;
    width: 23px;
    height: 23px;
  }
}

.font-xsmall {
  font-size: $font-size-12;
}

.font-small {
  font-size: $font-size-14;
}

.font-medium {
  font-size: $font-size-16;
}

.direction-right {
  flex-direction: row-reverse;
}

.direction-left {
  flex-direction: row;
}

.paddingselect {
  padding: 6px 12px;
}

@mixin svgIcon($color) {
  @if $color == 'primary' {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30.727" height="30.727" fill="%237896ff"><path d="M29.994 10.183L15.363 24.812.733 10.184a2.5 2.5 0 1 1 3.536-3.536l11.095 11.093L26.461 6.647a2.5 2.5 0 1 1 3.533 3.536z"/></svg>')
      no-repeat;
  } @else if $color == 'darkColor' {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30.727" height="30.727" fill="%2365708b"><path d="M29.994 10.183L15.363 24.812.733 10.184a2.5 2.5 0 1 1 3.536-3.536l11.095 11.093L26.461 6.647a2.5 2.5 0 1 1 3.533 3.536z"/></svg>')
      no-repeat;
  } @else if $color == 'grayColor' {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30.727" height="30.727" fill="%23dadade"><path d="M29.994 10.183L15.363 24.812.733 10.184a2.5 2.5 0 1 1 3.536-3.536l11.095 11.093L26.461 6.647a2.5 2.5 0 1 1 3.533 3.536z"/></svg>')
      no-repeat;
  } @else if $color == 'errorColor' {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30.727" height="30.727" fill="%23fb4e5f"><path d="M29.994 10.183L15.363 24.812.733 10.184a2.5 2.5 0 1 1 3.536-3.536l11.095 11.093L26.461 6.647a2.5 2.5 0 1 1 3.533 3.536z"/></svg>')
      no-repeat;
  } @else {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30.727" height="30.727" fill="%23ffff"><path d="M29.994 10.183L15.363 24.812.733 10.184a2.5 2.5 0 1 1 3.536-3.536l11.095 11.093L26.461 6.647a2.5 2.5 0 1 1 3.533 3.536z"/></svg>')
      no-repeat;
  }

  background-size: 12px;
  background-position: calc(100% - 20px) calc(60% - 2px);
  background-repeat: no-repeat;
  background-color: $gray1;

  @include iOS() {
    background-position: calc(100% - 10px) calc(60% - 2px);
  }
}

@mixin primaryColor {
  color: $primary-color;
}

@mixin darkColor {
  color: $gray15;
}

@mixin whiteColor {
  color: $white-color;
}

@mixin grayColor {
  color: $text-black-color;
}

@mixin errorColor {
  color: $text-black-color;
}

.primaryBackground {
  @include svgIcon('white');
  background-color: $primary-color;
  @include whiteColor;
}

.darkBackground {
  @include svgIcon('white');
  background-color: $gray15;
  @include whiteColor;
}

.grayBackground {
  @include svgIcon('grayColor');
  background-color: $gray15;
  @include grayColor;
}

.errorBackground {
  @include svgIcon('errorColor');
  background-color: $gray15;
  @include errorColor;
}

.primaryBorder {
  border: 1px solid $primary-color;
  @include svgIcon('primary');
  @include primaryColor;
}

.primaryColor {
  fill: $primary-color;
}

.darkBorder {
  border: 1px solid $gray15;
  @include svgIcon('darkColor');
  @include darkColor;
}

.grayBorder {
  border: 1px solid $gray13;
  @include svgIcon('grayColor');
  @include grayColor;
}

.errorBorder {
  border: 1px solid $tertiary-color;
  @include svgIcon('errorColor');
  @include errorColor;
}

.filled {
  @extend .select;
}

.outline {
  @extend .select;
  background-color: transparent;
}
