.container {
  width: 100%;
  margin: 2% 0;
  height: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  &.carrouselMode {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}
