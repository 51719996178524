@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$medium-screen: 1130px;
$small-screen: 900px;
$xsmall-screen: 500px;

html {
  scroll-behavior: smooth;
}

.footerSimulator {
  display: none;
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 36px 0;
  border-radius: 4px;
  margin: 0 auto;
  border-top: 1px solid #ebebeb;

  > .top {
    @include flexBox(row, space-between);
    width: 100%;

    > .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-bottom: 10px;
      align-self: flex-start;
      max-width: 42ch;
      line-height: 24px;
      > .companyInformation {
        margin-left: 70px;
      }
      > .linkProfiler {
        display: flex;
        text-decoration: none;
        margin: 5px 3px;
        cursor: pointer;
      }
    }
  }
  > .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;

    .rightContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .pagimovel {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    button {
      background-color: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      cursor: pointer;
      :hover {
        filter: opacity(0.8);
        transition: all 400ms ease;
      }
      @include screen(max-sm) {
        margin-left: 8px;
      }
    }

    .pagimovelLogo {
      height: 28px;
      width: 120px;
      margin-left: 7px;
      margin-right: 40px;
    }

    .socialNetworks {
      display: inline-flex;
      height: 32px;
      justify-content: center;

      > .linkNetwork {
        width: 32px;
        margin-right: 8px;
      }
    }
    .cnpj {
      display: inline-block;
      margin-top: 8px;
    }
  }

  @include screen(max-md) {
    .top {
      border-bottom: 1px solid #ebebeb;
      padding-bottom: 36px;
      margin-bottom: 36px;
      .container {
        .companyInformation {
          margin-left: 0;
        }
      }
    }
  }

  @include screen(min-lg) {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1180px;

    > .top {
      > .container {
        > .companyInformation {
          margin-left: 0;
        }
      }
      @include screen(max-md) {
        @include flexBox(column);
      }
    }
  }
}

.logoContainer {
  max-width: 141px;
  max-height: 48px;
  padding-bottom: 10px;
  > .logo {
    width: 100%;
    height: 100%;
  }
}

@include screen(max-md) {
  .footer {
    padding: 0 16px 24px;
    .top {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 36px;

      .container {
        width: 100%;
        margin-bottom: 10px;
        .logoContainer {
          align-self: flex-start;
        }
      }
    }

    .bottom {
      flex-direction: column;
      padding: 10px;
      margin-bottom: 12%;
      .rightContainer {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
      }
      .pagimovelLogo {
        margin-left: 0px;
        margin-right: 0px;
      }

      .pagimovel {
        > img {
          margin-left: 12px;
        }
      }
      .socialNetworks {
        margin-top: 24px;
      }
    }
  }
  .logoContainer {
    align-self: center;
    margin-bottom: 10px;
  }
  .cnpj {
    max-width: 25ch;
  }
}

.majorList {
  @include flexBox(row, space-between, flex-start);
  max-width: 650px;
  flex: 1;

  li {
    > h3 {
      font-size: $font-size-18;
      margin-bottom: 16px;
      line-height: 27px;
      font-weight: 700;
    }
  }
  .linksList {
    li {
      margin-bottom: 12px;

      > button {
        background-color: transparent;
        border: none;
        padding: 0;
      }

      a {
        text-decoration: none;
        color: $blue3;
        font-size: $font-size-16;
        line-height: 24px;
      }
      :hover {
        color: $gray5;
        transition: all 200ms ease-in-out;
      }
    }
  }
  @include screen(max-md) {
    @include flexBox(column, flex-start, flex-start);
  }
}
.hiddenButton {
  background-color: transparent;
  border: none;
}
