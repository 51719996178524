@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.halfButton {
  width: 50%;
}

.largeButton {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.horizontalContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 5px;

  > .largeButton {
    margin: 0;
  }
}

@include screen(max-md) {
  .largeButton {
    width: 98%;
  }
  .halfButton {
    width: 48%;
  }
}
