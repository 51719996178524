@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.simulatorContainer {
  max-width: 980px;
  margin: 64px auto 120px;

  .mainTitle {
    font-size: $font-size-36;
    margin-bottom: 50px;
    text-align: center;
  }
}
