@import '../../assets/scss/variables.scss';

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.value {
  font-size: 16px;
}

.strike {
  text-decoration: line-through;
}

.campaignAppraise {
  align-items: center;
  width: 100%;

  > p {
    font-size: 14px;
  }
}
